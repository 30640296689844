import React from "react";
import "./styles/Productos.css";
import LinkButton from "../componentes/LinkButton";
import "jspdf-autotable";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import ReactExport from "react-data-export";
import swal from "sweetalert";
import ProductoService from "../services/ProductoService";
import {notificarError} from "../componentes/Almacenes/AlmacenNotify";
import {ticketProductoPDF} from "../componentes/Caja/printDataCaja";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


function getDataExportExcel(dataSet) {
    const data = dataSet.filter(x => x);
    return [
        {
            columns: [
                {
                    title: "Código",
                    width: {wpx: 80}
                },
                {
                    title: "Código de barras",
                    width: {wpx: 80}
                },
                {
                    title: "Nombre",
                    width: {wpx: 250}
                },
                {
                    title: "Descripción",
                    width: {wpx: 250}
                },
                {
                    title: "Stock Sunat",
                    width: {wpx: 80}
                },
                {
                    title: "Precio Menor con IGV",
                    width: {wpx: 120}
                },

                {
                    title: "Precio Dolar x Und",
                    width: {wpx: 80}
                },
                {
                    title: "Precio Costo sin IGV",
                    width: {wpx: 120}
                },
                {
                    title: "Precio Costo con IGV",
                    width: {wpx: 120}
                },
                {
                    title: "Precio Menor sin IGV",
                    width: {wpx: 120}
                },
                {
                    title: "Precio Mayor sin IGV",
                    width: {wpx: 120}
                },
                {
                    title: "Precio Mayor con IGV",
                    width: {wpx: 120}
                },
                {
                    title: "Precio Mayor Cantidad",
                    width: {wpx: 120}
                },
                {
                    title: "Moneda",
                    width: {wpx: 80}
                },
                {
                    title: "Unidad de Medida",
                    width: {wpx: 150}
                },
                {
                    title: "Afectación",
                    width: {wpx: 300}
                },
                {
                    title: "Existencia",
                    width: {wpx: 250}
                },

                {
                    title: "Stock",
                    width: {wpx: 80}
                },
            ],

            data: data.map(row => {
                return [
                    {
                        value: row.codigo,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.CodigoBarra || "",
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.nombre,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.descripcion || "",
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.sdcc,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {

                      value: row.precioUnidadDolar? row.precioUnidadDolar.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }) : 0,
                        style: {
                            fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
                        }
                    },
                    {
                        value: row.PrecioCosto.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }),
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.PrecioCostoConIgv.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }),
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Precio.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }),
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.PrecioConIgv.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }),
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.PrecioMayor.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }),
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.PrecioMayorConIgv.toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }),
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.PrecioMayorCantidad,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Abreviatura,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.unidad_de_medida,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.tipo_de_afectacion_IGV,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.tipo_de_existencia,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.StockDisponibleSinComprobante,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                ];
            })
        }
    ];
}

class ProductosUI extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            dataProds: []
        }
        this.prodService = new ProductoService();
        this.generarReporte = this.generarReporte.bind(this);
        this.fetchAllProds = this.fetchAllProds.bind(this);
        this.printProduct = this.printProduct.bind(this);
    }

    async generarReporte() {
        const willGenerate = await swal({
            title: "Reporte de excel de todos los productos",
            text: "Esto va a tomar un tiempo acorde a la cantidad de productos registrados, ¿está seguro?",
            icon: "info",
            buttons: true,
            closeOnClickOutside: false
        });
        if (willGenerate)
            this.fetchAllProds();
    }

    async printProduct(product) {
        //console.log({product})
        await ticketProductoPDF(product)
        return
    }


    async fetchAllProds() {
        this.setState({isLoading: true, dataProds: []})
        let prods = await this.prodService.filtrarProds({
            page: 1,
            pageSize: 10,
            sorted: [],
            filtered: []
        }, {filterAll: "1"})
        if (prods) {
            const dataExport = getDataExportExcel(prods.productos);
            this.setState({dataProds: dataExport})
        } else
            notificarError("No se ha podido extraer todos los productos.")
        this.setState({isLoading: false})
    }

    render() {
        const props = this.props;
        const table = props.tableInfo;
        return (
            <section className="ventas-fpay-section-vh pt-5">
                <div className="container">
                    <div className="ProcesarBoton mt-1 mb-3">
                        {/* <BotonCancelar
                            onClick={props.history.goBack}
                            className=""
                        /> */}
                        <div className="ProcesarBotones">
                            <LinkButton to="/productos/new" className="btn-verde-mfp mr-2"
                                // title="Añadir Nuevo Producto"
                            >
                                {/* <i className="fas fa-plus" /> */}
                                <span className="text-22px-20pxresp">Crear Producto</span>
                            </LinkButton>

                            <button
                                className="btn-excel-mfp"
                                title="Descargar datos en EXCEL"
                                disabled={this.state.isLoading}
                                onClick={this.generarReporte}
                            >
                                <a className="">Excel</a>
                            </button>

                            {this.state.dataProds.length ? (
                                <ExcelFile
                                    element={
                                        <button disabled={this.state.isLoading}

                                            className="btn btn-naranja-mfp ml-2">Descargar</button>

                                    }
                                    filename="ReporteProductos"
                                >
                                    <ExcelSheet
                                        dataSet={this.state.dataProds}
                                        name="Productos"
                                    >
                                    </ExcelSheet>
                                </ExcelFile>
                            ) : null}
                        </div>

                    </div>
                    {
                        <ReactTable
                            id="table-productos-to-xls"
                            data={props.data}
                            pages={table.pages}
                            loading={table.isLoading}
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value
                            }
                            manual={true}
                            filterable={true}
                            sortable={true}
                            onFetchData={(state, instance) => props.onFilterProds(state, instance)}
                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: "Opciones",
                                            id: "id",
                                            width: 220,
                                            accessor: d => d.id,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {
                                                    keys: ["id"]
                                                }),
                                            Cell: f => {
                                                return (
                                                    <div align="center">
                                                        <LinkButton
                                                            to={`/productos/edit/${f.value}`}
                                                            className="btn-naranja-mfp"
                                                            onClick={props.handleOnClickProducto(f.original.id)}
                                                            style={{fontSize: "16px"}}
                                                        >
                                                            {<a className="">Editar</a>}
                                                        </LinkButton>
                                                        <button
                                                            id={f.value}
                                                            value={f.original.id}
                                                            type="button"
                                                            className="btn-anular ml-1"
                                                            onClick={props.handleOnDeleteProducto(f.original.id)}
                                                            style={{fontSize: "16px"}}
                                                        >
                                                            {<a className="">Borrar</a>}
                                                        </button>

                                                        <button
                                                            id={f.value}
                                                            value={f.original.id}
                                                            type="button"
                                                            className="btn-verde-mfp ml-1"
                                                            onClick={() => this.printProduct(f.original)}
                                                            style={{fontSize: "16px"}}
                                                        >
                                                            {<a className="">Imprimir</a>}
                                                        </button>
                                                    </div>
                                                );
                                            },

                                            filterAll: true
                                        },
                                        {
                                            Header: "Código",
                                            id: "codigo",
                                            width: 90,
                                            accessor: d => d.codigo,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["codigo"]}),
                                            filterAll: true
                                        },
                                        {
                                            Header: "CodigoBarra",
                                            id: "CodigoBarra",
                                            width: 110,
                                            accessor: d => d.CodigoBarra,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["CodigoBarra"]}),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Nombre",
                                            id: "nombre",
                                            width: 350,
                                            accessor: d => d.nombre,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["nombre"]}),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Stock Sunat",
                                            id: "StockDisponibleConComprobante",
                                            accessor: d => d.sdcc,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {
                                                    keys: ["sdcc"]
                                                }),
                                            filterAll: true
                                        },
                                        {
                                            Header: "P. Menor con IGV", //Con IGV
                                            id: "PrecioConIgv",
                                            width: 150,
                                            accessor: d => d.Simbolo + " " + d.PrecioConIgv.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }),
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {
                                                    keys: ["PrecioConIgv"]
                                                }),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        textAlign: "right"
                                                    }
                                                };
                                            }
                                        },

                                        {
                                            Header: "Precio Dolar x Und",
                                            id: "precioUnidadDolar",
                                            accessor: d => d.precioUnidadDolar,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {
                                                    keys: ["precioUnidadDolar"]
                                                }),
                                            filterAll: true
                                        },
                                        {
                                            Header: "P. Costo con IGV",
                                            id: "PrecioCostoConIgv",
                                            width: 90,
                                            accessor: d => d.PrecioCostoConIgv.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }),
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {
                                                    keys: ["PrecioCostoConIgv"]
                                                }),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        textAlign: "right"
                                                    }
                                                };
                                            }
                                        },

                                        {
                                            Header: "Descripción",
                                            id: "descripcion",
                                            width: 250,
                                            accessor: d => d.descripcion,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {keys: ["descripcion"]}),
                                            filterAll: true
                                        },
                                        {
                                            Header: "P. Mayor con IGV", //Con IGV
                                            id: "PrecioMayorConIgv",
                                            width: 150,
                                            accessor: d => d.Simbolo + " " + d.PrecioMayorConIgv.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }),
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {
                                                    keys: ["PrecioMayorConIgv"]
                                                }),
                                            filterAll: true,
                                            getProps: (state, rowInfo, column) => {
                                                return {
                                                    style: {
                                                        textAlign: "right"
                                                    }
                                                };
                                            }
                                        },
                                        {
                                            Header: "Moneda",
                                            id: "Abreviatura",
                                            width: 80,
                                            accessor: d => d.Abreviatura,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {
                                                    keys: ["Abreviatura"]
                                                }),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Unidad de Medida",
                                            id: "unidad_de_medida",
                                            accessor: d => d.unidad_de_medida,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {
                                                    keys: ["unidad_de_medida"]
                                                }),
                                            filterAll: true
                                        },
                                        {
                                            Header: "Tipo de Afectación IGV",
                                            id: "tipo_de_afectacion_IGV",
                                            accessor: d => d.tipo_de_afectacion_IGV,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {
                                                    keys: ["tipo_de_afectacion_IGV"]
                                                }),
                                            filterAll: true
                                        },

                                        {
                                            Header: "Stock",
                                            id: "StockDisponibleSinComprobante",
                                            width: 70,
                                            accessor: d => d.StockDisponibleSinComprobante,
                                            filterMethod: (filter, rows) =>
                                                matchSorter(rows, filter.value, {
                                                    keys: ["StockDisponibleSinComprobante"]
                                                }),
                                            filterAll: true
                                        }

                                    ]
                                }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            previousText="Anterior"
                            nextText="Siguiente"
                            noDataText="No se encontraron registros"
                            loadingText="Cargando..."
                            ofText="de"
                            rowsText="filas"
                            pageText="Página"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                        />
                    }
                </div>
            </section>
        );
    }
}

export default ProductosUI;
